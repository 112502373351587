import { Beer, Utensils, ChevronsUpDown, Plus, Hotel, Home } from "lucide-react"
import { useEffect } from "react"

import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/sidebar/sidebar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/collections"
import { Sites } from "@/data/sites"
import { useSiteStore } from "@/stores/use-site-store"

import { Spinner } from "../ui/statuses"

export function Siteswitcher({ sites }: { sites: Sites[] }) {
  const { isMobile } = useSidebar()

  const activeSite = useSiteStore((state) => state.activeSite)
  const setActiveSite = useSiteStore((state) => state.setActiveSite)
  const initializeActiveSite = useSiteStore(
    (state) => state.initializeActiveSite
  )

  const selectedSite = sites.find((site) => site?.id === activeSite) ?? sites[0]

  useEffect(() => {
    if (sites.length > 0) {
      initializeActiveSite(sites[0].id) // Gunakan site pertama sebagai default
    }
  }, [sites, initializeActiveSite])

  const handleSiteChange = (siteId: number) => {
    setActiveSite(siteId)
  }

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground md:h-8 md:p-0"
            >
              <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                {selectedSite?.code === "hahatora" ? (
                  <Beer className="size-4" />
                ) : selectedSite?.code === "limakitchen" ? (
                  <Utensils className="size-4" />
                ) : selectedSite?.code === "hotel-suite5" ? (
                  <Hotel className="size-4" />
                ) : selectedSite?.code === "suite5" ? (
                  <Home className="size-4" />
                ) : (
                  <Spinner className="size-4 text-foreground" />
                )}
              </div>
              <div className="grid flex-1 text-left text-sm">
                <span className="mb-0.5 truncate font-semibold">
                  {selectedSite?.label ?? ""}
                </span>
                <span className="truncate text-xs">
                  {selectedSite?.plan ?? ""}
                </span>
              </div>
              <ChevronsUpDown className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            align="start"
            side={isMobile ? "bottom" : "right"}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-muted-foreground">
              Sites
            </DropdownMenuLabel>
            {sites.map((site) => (
              <DropdownMenuItem
                key={site.label}
                onClick={() => handleSiteChange(site.id)}
                className="gap-2 p-2"
              >
                <div className="flex size-6 items-center justify-center rounded-sm border">
                  {site.code === "hahatora" ? (
                    <Beer className="size-4" />
                  ) : site.code === "limakitchen" ? (
                    <Utensils className="size-4" />
                  ) : site.code === "hotel-suite5" ? (
                    <Hotel className="size-4" />
                  ) : site.code === "suite5" ? (
                    <Home className="size-4" />
                  ) : (
                    <Spinner className="size-4 text-foreground" />
                  )}
                </div>
                {site.label}
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator />
            <DropdownMenuItem className="gap-2 p-2" disabled>
              <div className="flex size-6 items-center justify-center rounded-md border bg-background">
                <Plus className="size-4" />
              </div>
              <div className="font-medium text-muted-foreground">Add site</div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
