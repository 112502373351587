import Axios, { InternalAxiosRequestConfig } from "axios"
import { toast } from "sonner"

import { env } from "@/config/env"
import { refreshToken } from "@/features/auth/api/refresh-token"
import { Cookie } from "@/utils/storage"

import { queryClient } from "./react-query"

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  if (config.headers) {
    config.headers.Accept = "application/json"
  }

  const token = Cookie.getAccessToken()
  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }

  return config
}

export const api = Axios.create({
  baseURL: `${env.API_URL}/${env.API_VERSION}`,
})

api.interceptors.request.use(authRequestInterceptor)
api.interceptors.response.use(
  (response) => {
    return response.data
  },
  async (error) => {
    if (error.response?.status !== 401) {
      const message = error.response?.data?.message || error.message
      toast.error(message)
    }

    // refresh token
    const originalRequest = error.config
    if (error.response?.status == 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const refreshTokenPromise = refreshToken({
          refresh_token: Cookie.getRefreshToken() || "",
        })

        toast.promise(refreshTokenPromise, {
          loading: "Refreshing session...",
          success: "Session refreshed",
          error: "Failed to refresh session",
        })

        const { data } = await refreshTokenPromise
        const { access_token, refresh_token } = data

        Cookie.setAccessToken(access_token)
        Cookie.setRefreshToken(refresh_token)

        originalRequest.headers.authorization = `Bearer ${access_token}`

        return api(originalRequest)
      } catch (error) {
        Cookie.clearTokens()
        queryClient.clear()
        toast.error("Session expired. Please login again.")
      }
    }

    return Promise.reject(error)
  }
)
