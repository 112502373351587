import { useQuery, queryOptions } from "@tanstack/react-query"

import { api } from "@/lib/api-client"
import { QueryConfig } from "@/lib/react-query"

import { AuthUserSiteResponse } from "../types/api"

export const getUserSite = (): Promise<AuthUserSiteResponse> => {
  return api.get(`/auth/sites`)
}

export const getUserSiteQueryOptions = () => {
  return queryOptions({
    queryKey: ["userSites"],
    queryFn: () => getUserSite(),
  })
}

type UseUserSiteOptions = {
  queryConfig?: QueryConfig<typeof getUserSiteQueryOptions>
}

export const useUserSite = ({ queryConfig }: UseUserSiteOptions = {}) => {
  return useQuery({
    ...getUserSiteQueryOptions(),
    ...queryConfig,
  })
}
