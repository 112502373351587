import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { useMemo } from "react"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"

import { ProtectedRoute } from "@/lib/auth"

import { AppRoot } from "./routes/app/root"
import ErrorPage from "./routes/error"

const createRouter = (queryClient: QueryClient) =>
  createBrowserRouter(
    [
      {
        path: "/",
        element: <Navigate to="/app" replace />,
      },
      {
        path: "/auth/login",
        errorElement: <ErrorPage />,
        lazy: async () => {
          const { LoginRoute } = await import("./routes/auth/login")
          return { Component: LoginRoute }
        },
      },
      {
        path: "/app",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute>
            <AppRoot />
          </ProtectedRoute>
        ),
        children: [
          {
            errorElement: <ErrorPage />,
            children: [
              {
                path: "",
                lazy: async () => {
                  const { DashboardRoute } = await import(
                    "./routes/app/dashboard"
                  )
                  return { Component: DashboardRoute }
                },
              },
              {
                path: "menu",
                errorElement: <ErrorPage />,
                children: [
                  {
                    path: "",
                    lazy: async () => {
                      const { MenusRoute, MenuLoader } = await import(
                        "./routes/app/menus"
                      )
                      return {
                        Component: MenusRoute,
                        loader: MenuLoader(queryClient),
                      }
                    },
                  },
                  {
                    path: "category-menu",
                    lazy: async () => {
                      const { CategoryMenuRoute, CategoryMenuLoader } =
                        await import("./routes/app/category-menus")
                      return {
                        Component: CategoryMenuRoute,
                        loader: CategoryMenuLoader(queryClient),
                      }
                    },
                  },
                ],
              },
              {
                path: "content",
                errorElement: <ErrorPage />,
                children: [
                  {
                    path: "site-content",
                    lazy: async () => {
                      const { SitesContentRoute, SitesContentLoader } =
                        await import("./routes/app/sites-content")
                      return {
                        Component: SitesContentRoute,
                        loader: SitesContentLoader(queryClient),
                      }
                    },
                  },
                ],
              },
              {
                path: "user-management",
                errorElement: <ErrorPage />,
                children: [
                  {
                    path: "",
                    lazy: async () => {
                      const { UsersRoute, UsersLoader } = await import(
                        "./routes/app/users"
                      )
                      return {
                        Component: UsersRoute,
                        loader: UsersLoader(queryClient),
                      }
                    },
                  },
                  {
                    path: "roles",
                    lazy: async () => {
                      const { RolesRoute, RolesLoader } = await import(
                        "./routes/app/roles/roles"
                      )
                      return {
                        Component: RolesRoute,
                        loader: RolesLoader(queryClient),
                      }
                    },
                  },
                  {
                    path: "roles/edit/:roleId",
                    lazy: async () => {
                      const { EditRoleRoute, EditRoleLoader } = await import(
                        "./routes/app/roles/edit-role"
                      )
                      return {
                        Component: EditRoleRoute,
                        loader: EditRoleLoader(queryClient),
                      }
                    },
                  },
                  {
                    path: "permissions",
                    lazy: async () => {
                      const { PermissionsRoute, PermissionsLoader } =
                        await import("./routes/app/permissions")
                      return {
                        Component: PermissionsRoute,
                        loader: PermissionsLoader(queryClient),
                      }
                    },
                  },
                ],
              },
              {
                path: "site-management",
                errorElement: <ErrorPage />,
                children: [
                  {
                    path: "",
                    lazy: async () => {
                      const { SitesRoute, SitesLoader } = await import(
                        "./routes/app/sites"
                      )
                      return {
                        Component: SitesRoute,
                        loader: SitesLoader(queryClient),
                      }
                    },
                  },
                  {
                    path: "sites-module",
                    lazy: async () => {
                      const { SitesModuleRoute, SitesModuleLoader } =
                        await import("./routes/app/sites-module")
                      return {
                        Component: SitesModuleRoute,
                        loader: SitesModuleLoader(queryClient),
                      }
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "*",
        lazy: async () => {
          const { NotFoundRoute } = await import("./routes/not-found-error")
          return { Component: NotFoundRoute }
        },
      },
    ],
    {
      basename: "/",
    }
  )

export const AppRouter = () => {
  const queryClient = useQueryClient()

  const router = useMemo(() => createRouter(queryClient), [queryClient])

  return <RouterProvider router={router} />
}
