import { Navigate } from "react-router-dom"
import { toast } from "sonner"

import { getUser } from "@/features/auth/api/get-user"
import { loginWithEmailAndPassword } from "@/features/auth/api/login"
import { logout } from "@/features/auth/api/logout"
import { AuthUserResponse, UsersMeResponse } from "@/features/auth/types/api"
import { LoginRequest } from "@/features/auth/types/form"
import { Cookie } from "@/utils/storage"

import { configureAuth } from "./react-query-auth"

async function handleUserResponse(
  data: AuthUserResponse
): Promise<UsersMeResponse> {
  const { access_token, refresh_token, user } = data.data
  Cookie.setAccessToken(access_token)
  Cookie.setRefreshToken(refresh_token)
  return { data: user }
}

async function userFn() {
  if (Cookie.getAccessToken() || Cookie.getRefreshToken()) {
    try {
      const { data } = await getUser()
      return { data }
    } catch (error) {
      Cookie.clearTokens()
    }
  }
  return null
}

async function loginFn(dataForm: LoginRequest): Promise<UsersMeResponse> {
  const response = await loginWithEmailAndPassword(dataForm)
  const { data } = await handleUserResponse(response)
  return { data }
}

async function logoutFn() {
  try {
    await logout()
  } catch (error) {
    toast.error("Failed to logout")
  }
}

const authConfig = {
  userFn,
  loginFn,
  logoutFn,
}

export const { useUser, useLogin, useLogout, AuthLoader } =
  configureAuth(authConfig)

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const user = useUser()

  if (!user.data) {
    return <Navigate to="/auth/login" replace />
  }

  return children
}
