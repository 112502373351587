import { BookText, Globe, Home, Users } from "lucide-react"

import { SidebarNavItem } from "@/types/nav"

export const navigation: SidebarNavItem[] = [
  {
    title: "Dashboard",
    href: "/app",
    icon: Home,
    section: "main",
    path: ["/app"],
    items: [
      {
        title: "Overview",
        href: "/app",
        section: "dashboard",
        path: ["/app"],
        items: [],
      },
    ],
  },
  {
    title: "Module",
    href: "/app/menu",
    icon: BookText,
    section: "main",
    path: ["/app/menu", "/app/menu/category-menu", "/app/content/site-content"],
    allowedPermissions: [
      "module.menu.all",
      "module.menucategory.all",
      "module.sitecontent.all",
    ],
    allowedModule: ["menu", "menu_category", "site_content"],
    items: [
      {
        title: "Menu",
        href: "/app/menu",
        section: "menu",
        path: ["/app/menu"],
        allowedPermissions: ["module.menu.all", "module.menu.all"],
        allowedModule: ["menu"],
        items: [],
      },
      {
        title: "Category Menu",
        href: "/app/menu/category-menu",
        section: "menu",
        path: ["/app/menu/category-menu"],
        allowedPermissions: [
          "module.menucategory.all",
          "module.menucategory.all",
        ],
        allowedModule: ["menu_category"],
        items: [],
      },
      {
        title: "Site Content",
        href: "/app/content/site-content",
        section: "content",
        path: ["/app/content/site-content"],
        allowedPermissions: [
          "module.sitecontent.all",
          "module.sitecontent.all",
        ],
        allowedModule: ["site_content"],
        items: [],
      },
    ],
  },
  {
    title: "User Management",
    href: "/app/user-management",
    icon: Users,
    section: "settings",
    path: [
      "/app/user-management",
      "/app/user-management/roles",
      "/app/user-management/roles/edit",
      "/app/user-management/permissions",
    ],
    allowedPermissions: [
      "permission.all",
      "permission.view",
      "role.all",
      "role.view",
      "user.all",
      "user.view",
    ],
    items: [
      {
        title: "Users",
        href: "/app/user-management",
        section: "user management",
        path: ["/app/user-management"],
        allowedPermissions: ["user.all", "user.view"],
        items: [],
      },
      {
        title: "Roles",
        href: "/app/user-management/roles",
        section: "user management",
        path: ["/app/user-management/roles", "/app/user-management/roles/edit"],
        allowedPermissions: ["role.all", "role.view"],
        items: [],
      },
      {
        title: "Permissions",
        href: "/app/user-management/permissions",
        section: "user management",
        path: ["/app/user-management/permissions"],
        allowedPermissions: ["permission.all", "permission.view"],
        items: [],
      },
    ],
  },
  {
    title: "Site Management",
    href: "/app/site-management",
    icon: Globe,
    section: "settings",
    path: ["/app/site-management", "/app/site-management/sites-module"],
    allowedPermissions: [
      "site.all",
      "site.view",
      "sitemodule.all",
      "sitemodule.view",
    ],
    items: [
      {
        title: "Sites",
        href: "/app/site-management",
        section: "site management",
        path: ["/app/site-management"],
        allowedPermissions: ["site.all", "site.view"],
        items: [],
      },
      {
        title: "Sites Module",
        href: "/app/site-management/sites-module",
        section: "site management",
        path: ["/app/site-management/sites-module"],
        allowedPermissions: ["sitemodule.all", "sitemodule.view"],
        items: [],
      },
    ],
  },
]
