import { Link, useLocation } from "react-router-dom"

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/sidebar/sidebar"
import { useAuthorization } from "@/lib/authorization"
import { SidebarNavItem } from "@/types/nav"
import { removeDynamicSegments } from "@/utils/url"

export function NavSubMenu({ items }: { items: SidebarNavItem[] }) {
  const { pathname } = useLocation()

  const { checkAccess } = useAuthorization()

  return (
    <>
      {items.map((item) =>
        item.items
          .reduce(
            (acc, item) => {
              const section = item.section || item.title
              const group = acc.find((g) => g.section === section)
              if (group) {
                group.items.push(item)
              } else {
                acc.push({ section, items: [item] })
              }
              return acc
            },
            [] as {
              section: string
              items: SidebarNavItem[]
            }[]
          )
          .map(
            (group) =>
              checkAccess({
                allowedModule: group.items[0].allowedModule,
                allowedPermissions: group.items[0].allowedPermissions,
              }) && (
                <SidebarGroup key={group.section}>
                  <SidebarGroupLabel className="capitalize">
                    {group.section}
                  </SidebarGroupLabel>
                  <SidebarMenu>
                    {group.items.map(
                      (item) =>
                        checkAccess({
                          allowedModule: item.allowedModule,
                          allowedPermissions: item.allowedPermissions,
                        }) && (
                          <SidebarMenuItem key={item.title}>
                            <SidebarMenuButton
                              asChild
                              isActive={item.path.includes(
                                removeDynamicSegments(pathname)
                              )}
                            >
                              <Link to={item.href}>{item.title}</Link>
                            </SidebarMenuButton>
                          </SidebarMenuItem>
                        )
                    )}
                  </SidebarMenu>
                </SidebarGroup>
              )
          )
      )}
    </>
  )
}
