import { useQuery } from "@tanstack/react-query"

import { api } from "@/lib/api-client"
import { QueryConfig } from "@/lib/react-query"

import { AuthPermissionModuleResponse } from "../types/api"

export const getModulePermission = ({
  siteId,
}: {
  siteId: number
}): Promise<AuthPermissionModuleResponse> => {
  return api.get(`auth/modules/${siteId}`)
}

export const getModulePermissionQueryOptions = ({
  siteId,
}: {
  siteId: number
}) => {
  return {
    queryKey: ["modulePermission", { siteId }],
    queryFn: () => getModulePermission({ siteId }),
  }
}

type UseModulePermissionResponseOptions = {
  siteId: number
  queryConfig?: QueryConfig<typeof getModulePermissionQueryOptions>
}

export const useModulePermission = ({
  siteId,
  queryConfig,
}: UseModulePermissionResponseOptions) => {
  return useQuery({
    ...getModulePermissionQueryOptions({ siteId }),
    ...queryConfig,
  })
}
