import { useTranslation } from "react-i18next"
import { useRouteError } from "react-router-dom"

import { ContentLayout } from "@/components/layouts"
import { Note } from "@/components/ui/statuses/note"

export type RouteError = {
  message: string
  status?: number
  statusText?: string
}

export default function ErrorPage() {
  const error = useRouteError() as RouteError
  console.error(error)

  const { t } = useTranslation()

  return (
    <ContentLayout title={t("ErrorTitle")}>
      <Note>
        {t("ErrorDescription")} {error?.message}
      </Note>
    </ContentLayout>
  )
}
