import { CommandMenu, NProgress } from "@/components/shared"
import { AppSidebar } from "@/components/sidebar/app-sidebar"
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "@/components/sidebar/sidebar"
import { ThemeSwitcher } from "@/components/sidebar/theme-switcher"
import { DynamicBreadcrumb } from "@/components/ui/navigation"
import { Separator } from "@/components/ui/surfaces"

export const DashboardLayout = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset className="flex h-screen flex-col overflow-x-hidden scrollbar-thin scrollbar-track-background scrollbar-thumb-muted-foreground/40">
        <NProgress />
        <header className="sticky top-0 z-50 flex h-16 shrink-0 items-center gap-2 border-border/40 bg-sidebar-accent/50 backdrop-blur transition-[width,height] ease-linear supports-[backdrop-filter]:bg-sidebar-accent/50">
          <div className="flex flex-1 items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <DynamicBreadcrumb />
            <div className="relative ml-auto flex w-full flex-1 items-center gap-2 md:ml-auto md:grow-0">
              <CommandMenu />
              <ThemeSwitcher />
            </div>
          </div>
        </header>
        <div className="flex flex-1 flex-col gap-4 bg-sidebar-accent/50 p-4 pt-0">
          <div className="min-h-screen flex-1 md:min-h-min">{children}</div>
        </div>
      </SidebarInset>
    </SidebarProvider>
  )
}
