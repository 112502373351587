import * as React from "react"

import { useModulePermission } from "@/features/auth/api/get-module-permission"
import { usePermission } from "@/features/auth/api/get-permission"
import { useUserSite } from "@/features/auth/api/get-user-site"
import { useSiteStore } from "@/stores/use-site-store"

type CheckAccessProps = {
  allowedPermissions?: string[]
}

export const useAuthorizationPermission = () => {
  const userPermissions = usePermission()
  const { data: sitesData } = useUserSite()
  const activeSite = useSiteStore((state) => state.activeSite)

  // Mencari site yang sesuai dengan activeSite
  const activeSiteData = React.useMemo(() => {
    return sitesData?.data.find((site) => site.id === activeSite)
  }, [sitesData, activeSite])

  // Mengubah prefix "module." menjadi prefix site yang aktif
  const updatePermissionsWithActiveSite = React.useCallback(
    (permissions: string[] | undefined): string[] | undefined => {
      if (!permissions || permissions.length === 0) return permissions

      return permissions.map((permission) => {
        if (permission.startsWith("module.") && activeSiteData) {
          return permission.replace("module.", `${activeSiteData.code}.`)
        }
        return permission
      })
    },
    [activeSiteData]
  )

  const checkAccess = React.useCallback(
    ({ allowedPermissions }: CheckAccessProps): boolean => {
      if (allowedPermissions && allowedPermissions.length > 0) {
        const updatedPermissions =
          updatePermissionsWithActiveSite(allowedPermissions)
        return (
          updatedPermissions?.some((permission) =>
            userPermissions.data?.data.permissions.includes(permission)
          ) ?? false
        )
      }
      return true
    },
    [userPermissions.data?.data.permissions, updatePermissionsWithActiveSite]
  )

  return { checkAccess }
}

export const useAuthorizationModule = () => {
  const siteId = useSiteStore((state) => state.activeSite) || 1
  const userPermissionsModule = useModulePermission({
    siteId,
    queryConfig: { enabled: !!siteId },
  })

  const checkAccess = React.useCallback(
    ({ allowedModule }: { allowedModule?: string[] }) => {
      if (allowedModule && allowedModule.length > 0) {
        return allowedModule.some((module) =>
          userPermissionsModule.data?.data.some((data) => data.code === module)
        )
      }

      return true
    },
    [userPermissionsModule.data?.data]
  )

  return { checkAccess }
}

export const useAuthorization = () => {
  const { checkAccess: checkPermissionAccess } = useAuthorizationPermission()
  const { checkAccess: checkModuleAccess } = useAuthorizationModule()

  const checkAccess = React.useCallback(
    ({
      allowedPermissions,
      allowedModule,
    }: {
      allowedPermissions?: string[]
      allowedModule?: string[]
    }) => {
      // Validasi akses permissions jika `allowedPermissions` ada
      const hasPermissionAccess =
        !allowedPermissions || checkPermissionAccess({ allowedPermissions })

      // Validasi akses modul jika `allowedModule` ada
      const hasModuleAccess =
        !allowedModule || checkModuleAccess({ allowedModule })

      // Gabungkan hasil dari kedua validasi akses
      return hasPermissionAccess && hasModuleAccess
    },
    [checkPermissionAccess, checkModuleAccess]
  )

  return { checkAccess }
}
