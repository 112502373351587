import { useQuery } from "@tanstack/react-query"

import { api } from "@/lib/api-client"
import { QueryConfig } from "@/lib/react-query"

import { AuthPermissionResponse } from "../types/api"

export const getPermission = (): Promise<AuthPermissionResponse> => {
  return api.get(`/auth/permissions`)
}

export const getPermissionQueryOptions = () => {
  return {
    queryKey: ["userPermission"],
    queryFn: () => getPermission(),
  }
}

type UsePermissionResponseOptions = {
  queryConfig?: QueryConfig<typeof getPermissionQueryOptions>
}

export const usePermission = ({
  queryConfig,
}: UsePermissionResponseOptions = {}) => {
  return useQuery({
    ...getPermissionQueryOptions(),
    ...queryConfig,
  })
}
