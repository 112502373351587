import { ChevronRight } from "lucide-react"
import { Link, useLocation } from "react-router-dom"

import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar,
} from "@/components/sidebar/sidebar"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/surfaces"
import { useAuthorization } from "@/lib/authorization"
import { SidebarNavItem } from "@/types/nav"
import { removeDynamicSegments } from "@/utils/url"

export function NavSettings({ items }: { items: SidebarNavItem[] }) {
  const { pathname } = useLocation()
  const { isMobile, toggleSidebar } = useSidebar()

  const { checkAccess } = useAuthorization()

  const getAccessibleHref = (menuItem: SidebarNavItem): string => {
    const accessibleSubItems = menuItem.items?.filter((subItem) =>
      checkAccess({
        allowedModule: subItem.allowedModule,
        allowedPermissions: subItem.allowedPermissions,
      })
    )
    return accessibleSubItems && accessibleSubItems.length > 0
      ? accessibleSubItems[0].href
      : menuItem.href
  }

  if (isMobile) {
    return (
      <SidebarGroup>
        <SidebarGroupLabel>Settings</SidebarGroupLabel>
        <SidebarMenu>
          {items.map((item) =>
            item.items && item.items.length > 1
              ? checkAccess({
                  allowedModule: item.allowedModule,
                  allowedPermissions: item.allowedPermissions,
                }) && (
                  <Collapsible
                    key={item.title}
                    asChild
                    defaultOpen={item.items.some((subItem) =>
                      subItem.path.includes(removeDynamicSegments(pathname))
                    )}
                    className="group/collapsible"
                  >
                    <SidebarMenuItem>
                      <CollapsibleTrigger asChild>
                        <SidebarMenuButton tooltip={item.title}>
                          {item.icon && <item.icon />}
                          <span>{item.title}</span>
                          <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                        </SidebarMenuButton>
                      </CollapsibleTrigger>
                      <CollapsibleContent>
                        <SidebarMenuSub>
                          {item.items.map(
                            (subItem) =>
                              checkAccess({
                                allowedModule: subItem.allowedModule,
                                allowedPermissions: subItem.allowedPermissions,
                              }) && (
                                <SidebarMenuSubItem key={subItem.title}>
                                  <SidebarMenuSubButton
                                    asChild
                                    isActive={subItem.path.includes(
                                      removeDynamicSegments(pathname)
                                    )}
                                  >
                                    <Link
                                      to={subItem.href}
                                      onClick={toggleSidebar}
                                    >
                                      <span>{subItem.title}</span>
                                    </Link>
                                  </SidebarMenuSubButton>
                                </SidebarMenuSubItem>
                              )
                          )}
                        </SidebarMenuSub>
                      </CollapsibleContent>
                    </SidebarMenuItem>
                  </Collapsible>
                )
              : checkAccess({
                  allowedModule: item.allowedModule,
                  allowedPermissions: item.allowedPermissions,
                }) && (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton
                      tooltip={item.title}
                      asChild
                      isActive={item.path.includes(
                        removeDynamicSegments(pathname)
                      )}
                    >
                      <Link to={item.href} onClick={toggleSidebar}>
                        {item.icon && <item.icon />}
                        <span>{item.title}</span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                )
          )}
        </SidebarMenu>
      </SidebarGroup>
    )
  } else {
    return (
      <SidebarGroup>
        <SidebarGroupContent className="px-1.5 md:px-0">
          <SidebarMenu>
            {items.map(
              (item) =>
                checkAccess({
                  allowedModule: item.allowedModule,
                  allowedPermissions: item.allowedPermissions,
                }) && (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton
                      tooltip={{
                        children: item.title,
                        hidden: false,
                      }}
                      asChild
                      className="px-2.5 md:px-2"
                      isActive={item.path.includes(
                        removeDynamicSegments(pathname)
                      )}
                    >
                      <Link
                        to={getAccessibleHref(item)}
                        className="flex items-center space-x-2"
                      >
                        {item.icon && <item.icon />}
                        <span>{item.title}</span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                )
            )}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
    )
  }
}
