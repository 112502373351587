import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import * as React from "react"
import { HelmetProvider } from "react-helmet-async"

import { MainErrorFallback } from "@/components/errors/main"
import { ThemeProvider } from "@/components/theme"
import { Spinner, Toaster } from "@/components/ui/statuses"
import { AuthLoader } from "@/lib/auth"
import { queryConfig } from "@/lib/react-query"

type AppProviderProps = {
  children: React.ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: queryConfig,
      })
  )
  return (
    <Sentry.ErrorBoundary fallback={MainErrorFallback}>
      <React.Suspense
        fallback={
          <div className="flex h-screen w-screen items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider defaultTheme="light">
              {import.meta.env.DEV && <ReactQueryDevtools />}
              <Toaster />
              <AuthLoader
                renderLoading={() => (
                  <div className="flex h-screen w-screen items-center justify-center">
                    <Spinner size="xl" />
                  </div>
                )}
              >
                {children}
              </AuthLoader>
            </ThemeProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </React.Suspense>
    </Sentry.ErrorBoundary>
  )
}
