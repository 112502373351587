import { Helmet, HelmetData } from "react-helmet-async"

type HeadProps = {
  title?: string
  description?: string
}

const helmetData = new HelmetData({})

export const Head = ({
  title = "",
  description = "Discover Unique Experiences: Dine, Unwind, and Enjoy",
}: HeadProps = {}) => {
  return (
    <Helmet
      helmetData={helmetData}
      title={title ? `${title} |  Suites5` : undefined}
      defaultTitle="CMS |  Suites5"
    >
      <meta name="description" content={description} />
    </Helmet>
  )
}
