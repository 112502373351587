import { cva, VariantProps } from "class-variance-authority"
import { Info, Triangle } from "lucide-react"
import * as React from "react"

const noteStyles = cva(
  "my-4 overflow-hidden rounded-lg border p-4 leading-4 [&_.nd]:block [&_.nd]:text-sm [&_[data-slot=icon]]:size-5 [&_[data-slot=icon]]:shrink-0 [&_a]:underline [&_a]:hover:underline [&_strong]:font-medium",
  {
    variants: {
      intent: {
        primary:
          "border-primary/35 bg-primary/10 leading-4 text-primary [&_a]:text-primary",
        secondary: [
          "text-secondary-fg [&_[data-slot=icon]]:text-secondary-fg [&_a]:text-secondary-fg border-border bg-secondary/50",
          "dark:[&_a]:text-secondary-fg dark:[&_[data-slot=icon]]:text-secondary-fg",
        ],
      },
    },
    defaultVariants: {
      intent: "primary",
    },
  }
)

interface NoteProps
  extends React.HtmlHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof noteStyles> {
  hideIndicator?: boolean
}

const Note = ({
  hideIndicator = false,
  intent = "primary",
  className,
  ...props
}: NoteProps) => {
  return (
    <div className={noteStyles({ intent, className })} {...props}>
      <div className="flex items-start gap-x-2.5">
        {!hideIndicator && (
          <div className="mt-px w-5 shrink-0">
            {["primary", "secondary"].includes(intent ?? "") ? (
              <Info data-slot="icon" />
            ) : (
              <Triangle data-slot="icon" />
            )}
          </div>
        )}
        <p className="nd text-sm">{props.children}</p>
      </div>
    </div>
  )
}

export { Note, type NoteProps }
